<aside class="px-4 py-8 w-80 flex flex-col">
  <img src="/assets/images/portal-logo.png" alt="Step Portal logo" class="px-4 pb-6 self-start" />

  <nav class="p-2 flex-1 flex flex-col custom-scroll-container">
    <ul class="flex-1 flex flex-col gap-2">
      <li>
        <a
          routerLink="cluster"
          routerLinkActive="nav-item-active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
        >
          <app-svg-icon name="dataflow-01" class="text-primary-300"></app-svg-icon>

          <div class="flex-1 flex items-center justify-between gap-3">
            <div class="text-white">My clusters</div>

            <a
              routerLink="cluster/create-cluster"
              (click)="$event.stopPropagation()"
              class="p-1.5 text-white flex items-center rounded-full hover:bg-primary-600 focus:bg-primary-600 focus-ring-primary-2"
            >
              <app-svg-icon name="plus" class="text-xs"></app-svg-icon>
            </a>
          </div>
        </a>

        <ng-container *ngIf="clusters$ | async as clusters">
          <ul *ngIf="clusters.length" class="pl-7 pt-2 pr-2 pb-2 flex flex-col gap-2">
            <li *ngFor="let cluster of clusters">
              <a
                [routerLink]="['cluster/details', cluster.id]"
                routerLinkActive="nav-item-active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-cluster-status-icon
                  [clusterStatus]="cluster.state"
                  class="text-primary-300 flex"
                ></app-cluster-status-icon>

                <div class="flex-1 text-white whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {{ cluster.name }}
                </div>
              </a>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>

    <ul class="flex flex-col gap-2">
      <li>
        <a
          routerLink="billing"
          routerLinkActive="nav-item-active"
          [routerLinkActiveOptions]="{ exact: true }"
          class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
        >
          <app-svg-icon name="bank-note-01" class="text-primary-300"></app-svg-icon>

          <div class="flex-1">
            <div class="text-white justify-between flex">Billing
              <span class="text-sm" matTooltip="Current credit balance">{{ billing$ | async | extractbalance}}</span>
            </div>
          </div>
        </a>
      </li>

      <li>
        <a
          routerLink="settings"
          routerLinkActive="nav-item-active"
          class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
        >
          <app-svg-icon name="settings-01" class="text-primary-300"></app-svg-icon>

          <div class="flex-1">
            <div class="text-white">Settings</div>
          </div>
        </a>
      </li>

      <li class="group/tutorials">
        <button type="button" class="w-full rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2">
          <app-svg-icon name="book-open-01" class="text-primary-300"></app-svg-icon>

          <div class="flex-1 text-white text-left">Tutorials</div>

          <div
            class="text-xs text-primary-300 flex group-focus-within/tutorials:rotate-180 transition-all duration-300"
          >
            <app-svg-icon name="chevron-down"></app-svg-icon>
          </div>
        </button>

        <div
          #tutorialsMenu
          [style.--tutorialsMenuHeight.px]="tutorialsMenu.scrollHeight"
          class="h-0 opacity-0 overflow-hidden group-focus-within/tutorials:h-[var(--tutorialsMenuHeight)] group-focus-within/tutorials:opacity-100 transition-all duration-300"
        >
          <ul class="pl-7 pt-2 pr-2 pb-2 flex flex-col gap-2">
            <li>
              <a
                href="https://step.exense.ch/resources/tutorials/load-testing-with-cypress/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="loading-02" class="text-primary-300 self-start h-6 flex items-center"></app-svg-icon>
                <span class="text-white">Load Testing with Cypress</span>
              </a>
            </li>

            <li>
              <a
                href="https://step.exense.ch/resources/tutorials/load-testing-with-playwright/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="loading-02" class="text-primary-300 self-start h-6 flex items-center"></app-svg-icon>
                <span class="text-white">Load Testing with Playwright</span>
              </a>
            </li>

            <li>
              <a
                href="https://step.exense.ch/resources/tutorials/load-testing-with-selenium/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="loading-02" class="text-primary-300 self-start h-6 flex items-center"></app-svg-icon>
                <span class="text-white">Load Testing with Selenium</span>
              </a>
            </li>

            <li>
              <a
                href="https://step.exense.ch/resources/tutorials/robotic-process-automation-rpa-with-cypress/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="cpu-chip-01" class="text-primary-300 self-start h-6 flex items-center"></app-svg-icon>
                <span class="text-white">Robotic Process Automation (RPA) with Cypress</span>
              </a>
            </li>

            <li>
              <a
                href="https://step.exense.ch/resources/tutorials/robotic-process-automation-rpa-with-playwright/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="cpu-chip-01" class="text-primary-300 self-start h-6 flex items-center"></app-svg-icon>
                <span class="text-white">Robotic Process Automation (RPA) with Playwright</span>
              </a>
            </li>

            <li>
              <a
                href="https://step.exense.ch/resources/tutorials/robotic-process-automation-with-selenium/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="cpu-chip-01" class="text-primary-300 self-start h-6 flex items-center"></app-svg-icon>
                <span class="text-white">Robotic Process Automation (RPA) with Selenium</span>
              </a>
            </li>

            <li>
              <a
                href="https://step.exense.ch/resources/tutorials/synthetic-monitoring-with-cypress/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="monitor-01" class="text-primary-300 self-start h-6 flex items-center"></app-svg-icon>
                <span class="text-white">Synthetic Monitoring with Cypress</span>
              </a>
            </li>

            <li>
              <a
                href="https://step.exense.ch/resources/tutorials/synthetic-monitoring-with-playwright/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="monitor-01" class="text-primary-300 self-start h-6 flex items-center"></app-svg-icon>
                <span class="text-white">Synthetic Monitoring with Playwright</span>
              </a>
            </li>

            <li>
              <a
                href="https://step.exense.ch/resources/tutorials/synthetic-monitoring-with-selenium/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="monitor-01" class="text-primary-300 self-start h-6 flex items-center"></app-svg-icon>
                <span class="text-white">Synthetic Monitoring with Selenium</span>
              </a>
            </li>
          </ul>
        </div>
      </li>

      <li class="group/support">
        <button type="button" class="w-full rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2">
          <app-svg-icon name="life-buoy-02" class="text-primary-300"></app-svg-icon>

          <div class="flex-1 text-white text-left">Support</div>

          <div class="text-xs text-primary-300 flex group-focus-within/support:rotate-180 transition-all duration-300">
            <app-svg-icon name="chevron-down"></app-svg-icon>
          </div>
        </button>

        <div
          #supportMenu
          [style.--supportMenuHeight.px]="supportMenu.scrollHeight"
          class="h-0 opacity-0 overflow-hidden group-focus-within/support:h-[var(--supportMenuHeight)] group-focus-within/support:opacity-100 transition-all duration-300"
        >
          <ul class="pl-7 pt-2 pr-2 pb-2 flex flex-col gap-2">
            <li>
              <a
                href="https://step.exense.ch/knowledgebase/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="graduation-hat-02" class="text-primary-300"></app-svg-icon>
                <span class="text-white">Knowledge base</span>
              </a>
            </li>

            <li>
              <a
                href="https://step.exense.ch/product/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="info-circle" class="text-primary-300"></app-svg-icon>
                <span class="text-white">About Step</span>
              </a>
            </li>

            <li>
              <a
                href="https://step.exense.ch/support/"
                target="_blank"
                class="rounded-md py-2 px-3 flex items-center gap-3 focus-ring-primary-2"
              >
                <app-svg-icon name="headphones-01" class="text-primary-300"></app-svg-icon>
                <span class="text-white">Contact</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>

  <div class="pt-2 pb-6">
    <div class="border-t border-primary-600"></div>
  </div>

  <app-sidebar-profile
    *ngIf="user$ | async as user"
    [fullName]="user.fullName"
    [email]="user.email"
    (logout)="onLogout()"
  ></app-sidebar-profile>
</aside>

<main class="flex-1 min-w-0 pt-3">
  <div #scrollableContainer class="p-8 rounded-tl-[2.5rem] bg-white h-full overflow-auto">
    <router-outlet></router-outlet>
  </div>
</main>
