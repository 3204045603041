import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { BillingPeriodType, CustomerPrices } from 'src/app/_models/billing';
import { AgentType } from 'src/app/_services/agents.token';
import { SpinnerService } from 'src/app/_services/spinner.service';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/components/button/button.component';
import { SkeletonLoaderRounding } from 'src/app/components/skeleton-loader/skeleton-loader.component';
import { BillingService } from '../../_services/billing.service';
import { PortalService } from '../../_services/portal.service';
import {
  AgentsConfigurationComponent,
  AgentsControlValue,
} from '../agents-configuration/agents-configuration.component';
import { PluginTypesControlValue } from '../plugins-configuration/plugins-configuration.component';
import {IndividualConfig} from "ngx-toastr/toastr/toastr-config";

const DEFAULT_MAX_QUOTA = 10;

@Component({
  selector: 'app-create-cluster',
  templateUrl: './create-cluster.component.html',
  styleUrls: ['./create-cluster.component.scss'],
})
export class CreateClusterComponent implements OnInit {
  @ViewChild(AgentsConfigurationComponent) agentsConfigurationComponent!: AgentsConfigurationComponent;

  readonly formGroup = this.formBuilder.group({
    clusterName: ['', [Validators.required, Validators.pattern('^[a-z-0-9]*$')]],
    billingPeriodType: [BillingPeriodType.HOUR],
    agents: [
      {
        [AgentType.JAVA_UI_AUTOMATION]: 1,
      } as AgentsControlValue,
    ],
    pluginTypes: [[] as PluginTypesControlValue],
  });
  readonly billingPeriod = this.billingService.PERIOD;
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;
  readonly ENABLE_PLUGINS = false; // disabled since no working plugins available at the moment

  loading: boolean = false;
  maxQuota: number = DEFAULT_MAX_QUOTA;
  customerPrices?: CustomerPrices;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private portalService: PortalService,
    private toastrService: ToastrService,
    private billingService: BillingService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.loading = true;

    forkJoin([
      this.portalService.getNameSuggestion(),
      this.billingService.getQuota(),
      this.billingService.getCustomerPrices(),
    ]).subscribe({
      next: ([suggestedName, maxQuota, customerPrices]) => {
        this.formGroup.controls.clusterName.setValue(suggestedName);
        this.maxQuota = maxQuota;
        this.customerPrices = customerPrices;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  onSubmit(): void {
    const { clusterName, agents, pluginTypes } = this.formGroup.value;

    Object.keys(agents).forEach((key) => {
      if (!agents[key]) {
        delete agents[key];
      }
    });

    this.spinnerService.show();

    this.portalService.createCluster(clusterName, agents, pluginTypes).subscribe({
      next: ({ id }) => {
        this.spinnerService.hide();

        const toastrOverride : Partial<IndividualConfig> = {disableTimeOut: true};
        this.toastrService.success('It may take up to 5 minutes for your cluster to be created, wait for it to ' +
          'reach the RUNNING state and login with the provided credentials.', 'Creating cluster', toastrOverride);
        this.router.navigate(['/cluster/details/', id]);
      },
      error: () => {
        this.spinnerService.hide();
      },
    });
  }
}
