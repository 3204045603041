<form [formGroup]="formGroup" (submit)="onSubmit()">
  <div class="flex justify-between">
    <h1 class="text-3xl leading-3xl font-medium text-gray-900">{{ cluster?.name }}</h1>

    <app-button
      *ngIf="!editMode; else editModeActionsTemplate"
      [buttonType]="ButtonType.BUTTON"
      [buttonStyle]="ButtonStyle.BTN_1"
      [buttonPadding]="ButtonPadding.BTN_PADDING_0"
      [disabled]="loading"
      (buttonClick)="onToggleEditMode()"
    >
      Edit cluster
    </app-button>

    <ng-template #editModeActionsTemplate>
      <div class="flex gap-3">
        <app-button
          [buttonType]="ButtonType.BUTTON"
          [buttonStyle]="ButtonStyle.BTN_1"
          [buttonPadding]="ButtonPadding.BTN_PADDING_0"
          (buttonClick)="onCancel()"
        >
          Cancel
        </app-button>

        <app-button
          [buttonType]="ButtonType.SUBMIT"
          [buttonStyle]="ButtonStyle.BTN_0"
          [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        >
          Update cluster
        </app-button>
      </div>
    </ng-template>
  </div>

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
          Status
          <app-svg-icon
            name="info-circle"
            matTooltip="The current state of your cluster. You will only be charged for usage while in 'running' state"
          ></app-svg-icon>
        </div>
      </div>

      <div class="flex-[4] flex items-center gap-2">
        <ng-container *ngIf="cluster">
          <app-cluster-status [clusterStatus]="cluster.state"></app-cluster-status>

          <button
            type="button"
            [disabled]="
              cluster.state === ClusterStatus.RUNNING ||
              cluster.state === ClusterStatus.STARTING ||
              cluster.state === ClusterStatus.STOPPING ||
              statusChangeOngoingByCluster[cluster.id]
            "
            matRipple
            matTooltip="Start cluster"
            (click)="startCluster(cluster)"
            class="focus:outline-none focus:text-primary-500 disabled:text-gray-300 p-2 rounded-full text-gray-500 text-lg"
          >
            <app-svg-icon name="play-circle"></app-svg-icon>
          </button>

          <button
            type="button"
            [disabled]="
              cluster.state === ClusterStatus.STOPPED ||
              cluster.state === ClusterStatus.STOPPING ||
              cluster.state === ClusterStatus.STARTING ||
              statusChangeOngoingByCluster[cluster.id]
            "
            matRipple
            matTooltip="Stop cluster"
            (click)="stopCluster(cluster)"
            class="focus:outline-none focus:text-primary-500 disabled:text-gray-300 p-2 rounded-full text-gray-500 text-lg"
          >
            <app-svg-icon name="stop-circle"></app-svg-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </app-skeleton-loader>

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700">Cluster URL</div>
      </div>

      <div class="flex-[4]">
        <a [href]="cluster?.url" target="_blank" class="text-sm leading-sm link-0">
          {{ cluster?.url }}
        </a>
      </div>
    </div>
  </app-skeleton-loader>

  <div class="py-5">
    <hr/>
  </div>

  <div class="flex gap-8">
    <div class="flex-[2]">
      <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
        Credentials
        <app-svg-icon
          name="info-circle"
          matTooltip="These are the credentials of the cluster, note that if you have multiple cluster, each has its own credentials."
        ></app-svg-icon>
      </div>
    </div>

    <div class="flex-[4]">
      <app-skeleton-loader [loading]="loading || (clusterPasswordById[clusterId!] && !clusterPasswordById[clusterId!].clusterPasswordInitialized)"
                           [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
        <div class="flex-[4] flex items-center gap-4">
          <mat-form-field subscriptSizing="dynamic">
            <app-svg-icon name="user-circle" matPrefix></app-svg-icon>
            <input matInput type="text" [formControl]="formGroup.controls.username | asFormControl"/>
          </mat-form-field>

          <mat-form-field subscriptSizing="dynamic" class="with-suffix"
                          *ngIf="clusterPasswordById[clusterId!]?.clusterPassword || cluster?.state === ClusterStatus.STARTING; else noClusterPassword">
            <app-svg-icon name="lock-04" matPrefix></app-svg-icon>
            <input #passwordInput matInput type="password" [formControl]="formGroup.controls.password | asFormControl"/>
            <app-password-visibility [inputElement]="passwordInput" matSuffix></app-password-visibility>
          </mat-form-field>
            <app-svg-icon
                *ngIf="clusterPasswordById[clusterId!]?.clusterPassword || cluster?.state === ClusterStatus.STARTING"
                class="large"
                name="info-circle"
                matTooltip="For security reasons the password is only available in the session in which the cluster was created."
              ></app-svg-icon>
          <ng-template #noClusterPassword>
            <div class="text-sm leading-sm text-gray-500 flex items-center gap-2">
              Cluster password no longer available
              <app-svg-icon
                name="info-circle"
                matTooltip="For security reasons the password is only available in the session in which the cluster was created."
              ></app-svg-icon>
            </div>
            <app-button
              *ngIf="clusterPasswordById[clusterId!]?.clusterPasswordInitialized"
              [buttonType]="ButtonType.BUTTON"
              [buttonStyle]="ButtonStyle.BTN_1"
              [buttonPadding]="ButtonPadding.BTN_PADDING_0"
              [disabled]="cluster?.state !== ClusterStatus.RUNNING"
              (buttonClick)="resetClusterPassword()"
              [matTooltip]="cluster?.state !== ClusterStatus.RUNNING ? 'The cluster must be running to reset its password' : 'Resets the clusters password and shows it until this session ends'"
            >
              Reset password
            </app-button>
          </ng-template>
        </div>
      </app-skeleton-loader>
    </div>
  </div>

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700">Agents</div>
      </div>

      <div class="flex-[4] flex flex-col gap-3">
        <ng-container *ngIf="!editMode; else editModeAgentsTemplate">
          <ng-container *ngIf=" cluster?.agentPools?.length && cluster!.agentPools!.length > 0; else noAgents">
            <ng-container *ngFor="let agentPool of cluster?.agentPools">
              <ng-container *ngIf="agentPool.configured">
                <ng-container *ngIf="agentByType[agentPool.key] as agent">
                  <app-agent-details [agentCount]="agentPool.configured" [agent]="agent"></app-agent-details>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-template #noAgents>
            <div class="text-sm leading-sm text-gray-500">No agents were added to this cluster. Edit cluster to add
              agents.
            </div>
          </ng-template>
        </ng-container>

        <ng-template #editModeAgentsTemplate>
          <app-agents-configuration
            [billingPeriod]="billingPeriod[billingPeriodTypeControl.value]"
            [customerPrices]="customerPrices"
            [agentsControl]="formGroup.controls.agents | asFormControl"
            [maxQuota]="maxQuota"
          ></app-agents-configuration>
        </ng-template>
      </div>
    </div>
  </app-skeleton-loader>

  <div *ngIf="ENABLE_PLUGINS" class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader *ngIf="ENABLE_PLUGINS" [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700">Plugins</div>
      </div>

      <div class="flex-[4] flex flex-col gap-3">
        <ng-container *ngIf=" cluster?.plugins?.length && cluster!.plugins!.length > 0; else noPlugins">
          <ng-container *ngFor="let plugin of cluster?.plugins">
            <ng-container *ngIf="pluginByType[plugin.key] as plugin">
              <app-plugin-details [plugin]="plugin"></app-plugin-details>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #noPlugins>
          <div class="text-sm leading-sm text-gray-500">No plugins were added to this cluster. Plugins can only be added
            on cluster creation.
          </div>
        </ng-template>
      </div>
    </div>
  </app-skeleton-loader>

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
          Current cost
          <app-svg-icon
            name="info-circle"
            matTooltip="The cost of this cluster with the current agent and plugin configuration. Costs are only charged while the cluster is in 'running' state"
          ></app-svg-icon>
        </div>
      </div>

      <div class="flex-[4] flex items-center gap-1">
        <app-costs-switcher
          [customerPrices]="customerPrices"
          [agentsControlValue]="formGroup.controls.agents.value"
          [pluginTypesControlValue]="formGroup.controls.pluginTypes.value"
          [billingPeriodTypeControl]="billingPeriodTypeControl"
        ></app-costs-switcher>
      </div>
    </div>
  </app-skeleton-loader>

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
          Automatic start / stop cluster
          <app-svg-icon
            name="info-circle"
            matTooltip="Your Step cluster can automatically shutdown and start when you open its URL. This will safe costs but adds a short startup time when starting."
          ></app-svg-icon>
        </div>
      </div>

      <div class="flex-[4]">
        <mat-slide-toggle [formControl]="formGroup.controls.automaticStartStop | asFormControl"></mat-slide-toggle>
      </div>
    </div>
  </app-skeleton-loader>

  <ng-container *ngIf="formGroup.controls.automaticStartStop.value">
    <div class="py-5">
      <hr/>
    </div>

    <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
      <div class="flex gap-8">
        <div class="flex-[2]">
          <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
            Grace period in minutes
            <app-svg-icon
              name="info-circle"
              matTooltip="The grace period after which the cluster did not receive any input before it is shut down. (default: 30 min)"
            ></app-svg-icon>
          </div>
        </div>

        <div class="flex-[4]">
          <mat-slider
            [disabled]="!editMode"
            [min]="5"
            [max]="300"
            [step]="5"
            [discrete]="false"
            class="w-full"
          ><input matSliderThumb [formControl]="formGroup.controls.gracePeriodInMinutes | asFormControl" #slider/></mat-slider>
          {{ slider.value }}
        </div>
      </div>
    </app-skeleton-loader>
  </ng-container>

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700">Danger zone - delete cluster</div>
      </div>

      <div class="flex-[4] flex items-center gap-4">
        <app-button
          [buttonType]="ButtonType.BUTTON"
          [buttonStyle]="ButtonStyle.BTN_2"
          [buttonPadding]="ButtonPadding.BTN_PADDING_0"
          (buttonClick)="deleteCluster()"
        >
          Delete cluster
        </app-button>
      </div>
    </div>
  </app-skeleton-loader>
</form>

<ng-template #headingTemplate>Are you sure?</ng-template>
<ng-template #descriptionTemplate>
  You are about to delete <strong>{{ cluster?.name }}</strong> cluster. Do you wish to continue?
</ng-template>
