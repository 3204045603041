import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Step | Portal';

  constructor(public router: Router) {
    if (environment.production) {
      const gTagId = 'G-6Y4RDJR8RZ';
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${gTagId}`;
      document.head.appendChild(gTagManagerScript);

      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', '${gTagId}');
    `;
      document.head.appendChild(gaScript);

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', gTagId, {
            page_path: event.urlAfterRedirects,
          });
        }
      });
    }
  }
}
