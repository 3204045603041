<div class="w-56 max-w-xs flex flex-col items-center gap-6">
  <app-spinner class="text-6xl"></app-spinner>

  <p *ngIf="message; else default" class="text-white text-lg text-center">
    {{ message }}
  </p>

  <ng-template #default>
      <p class="text-white text-lg text-center">Loading...</p>
  </ng-template>
</div>
