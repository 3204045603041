import { InjectionToken } from '@angular/core';

export enum AgentType {
  JAVA_UI_AUTOMATION = 'agent-java-ui-automation',
  JAVA_API_AUTOMATION = 'agent-java-api-automation',
  DOTNET_UI_AUTOMATION = 'agent-dotnet-ui-automation',
  DOTNET_API_AUTOMATION = 'agent-dotnet-api-automation',
}

export interface Agent {
  type: AgentType;
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
}

export const AGENTS = new InjectionToken<Agent[]>('agents', {
  providedIn: 'root',
  factory: () => {
    return [
      {
        type: AgentType.JAVA_UI_AUTOMATION,
        imgSrc: '/assets/svg/logos/java.logo.svg',
        imgAlt: 'Java logo',
        title: 'Agent [java, ui-automation]',
        description: `This pool of agents is designed for java UI automation (selenium, cypress...) with one browser or client per agent. The number of agents defines the number of scripts we'll be able to run in parallel`,
      },
      {
        type: AgentType.JAVA_API_AUTOMATION,
        imgSrc: '/assets/svg/logos/java.logo.svg',
        imgAlt: 'Java logo',
        title: 'Agent [java, api-automation]',
        description: `This pool of agents is designed for java (lightweight) automation code, such as protocol based testing. One agent is allowed to execute up to 100 scripts in parallel.`,
      },
      {
        type: AgentType.DOTNET_UI_AUTOMATION,
        imgSrc: '/assets/svg/logos/dotnet.logo.svg',
        imgAlt: '.NET logo',
        title: 'Agent [dotnet, ui-automation]',
        description: `This pool of agents is designed for dotnet UI automation with one browser or client per agent. The number of agents defines the number of scripts we'll be able to run in parallel`,
      },
      {
        type: AgentType.DOTNET_API_AUTOMATION,
        imgSrc: '/assets/svg/logos/dotnet.logo.svg',
        imgAlt: '.NET logo',
        title: 'Agent [dotnet, api-automation]',
        description: `This pool of agents is designed for dotnet (lightweight) automation code, such as protocol based testing. One agent is allowed to execute up to 100 scripts in parallel.`,
      },
    ];
  },
});
