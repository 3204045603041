<div class="flex min-h-screen">
  <div class="flex-1 flex flex-col">
    <div class="flex-1 flex sm:items-center justify-center">
      <div class="max-w-sm w-full p-4">
        <h2 class="pb-8 text-gray-900 text-3xl leading-3xl font-semibold">Reset password</h2>

        <form [formGroup]="formGroup" (submit)="onSubmit()" class="flex flex-col gap-2">
          <app-label [required]="true" [control]="formGroup.controls.password" title="Password" #passwordLabel></app-label>
          <mat-form-field subscriptSizing="dynamic" class="with-suffix">
            <input
              (focusin)="passwordLabel.focus()"
              (focusout)="passwordLabel.focusout()"
              #passwordInput
              matInput
              placeholder="Password"
              name="password"
              type="password"
              autocomplete="new-password"
              formControlName="password"
              class="relative z-[1]"
            />

            <app-password-visibility [inputElement]="passwordInput" matSuffix></app-password-visibility>

            <mat-hint>
              A secure password requires 8 letters that include: special characters, numbers, small and capital letters
            </mat-hint>

            <mat-error *ngIf="formGroup.controls.password.hasError('required')">Password is required</mat-error>

            <mat-error *ngIf="formGroup.controls.password.hasError('minlength')"> Password is too short </mat-error>
          </mat-form-field>

          <app-label [required]="true" [control]="formGroup.controls.confirmPassword" title="Confirm password" #confirmPasswordLabel></app-label>
          <mat-form-field subscriptSizing="dynamic" class="with-suffix pb-4">
            <input
              (focusin)="confirmPasswordLabel.focus()"
              (focusout)="confirmPasswordLabel.focusout()"
              #confirmPasswordInput
              matInput
              placeholder="Confirm password"
              name="confirmPassword"
              type="password"
              formControlName="confirmPassword"
              class="relative z-[1]"
            />

            <app-password-visibility [inputElement]="confirmPasswordInput" matSuffix></app-password-visibility>

            <mat-error *ngIf="formGroup.controls.confirmPassword.hasError('required')">
              Confirm password is required
            </mat-error>

            <mat-error *ngIf="formGroup.controls.confirmPassword.hasError('confirmPassword')">
              Passwords do not match
            </mat-error>
          </mat-form-field>

          <app-button
            [buttonType]="ButtonType.SUBMIT"
            [buttonStyle]="ButtonStyle.BTN_0"
            [buttonPadding]="ButtonPadding.BTN_PADDING_0"
            [fluid]="true"
          >
            Continue
          </app-button>
        </form>
      </div>
    </div>

    <app-auth-footer></app-auth-footer>
  </div>

  <div class="flex-1 hidden lg:block max-h-screen sticky top-0">
    <div class="inset-0 absolute">
      <img src="/assets/images/lake-zug.jpg" alt="Lake Zug" class="object-cover w-full h-full" />
    </div>

    <div class="inset-0 absolute">
      <img
        src="/assets/images/snowy-mountains-overlay.png"
        alt="Snowy mountains overlay"
        class="object-cover w-full h-full"
      />
    </div>

    <div class="inset-0 absolute flex items-center justify-center">
      <div class="max-w-lg flex flex-col gap-6 p-8 xl:p-4">
        <a
          routerLink="/login"
          matRipple
          class="text-white rounded-xl bg-primary-800 p-4 self-start text-xl focus-ring-primary-2 hover:bg-primary-600 transition-colors"
        >
          <app-svg-icon name="log-in-02"></app-svg-icon>
        </a>

        <h1 class="text-white text-6xl leading-6xl xl:text-7xl xl:leading-7xl font-medium">Try it for free!</h1>

        <p class="text-primary-200 text-lg leading-lg xl:text-xl xl:leading-xl">
          Welcome to the new step SaaS solution available in early access - get 20 CHF in free credits.
        </p>
      </div>
    </div>
  </div>
</div>
