import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { register } from 'swiper/element/bundle';
import { AddOrganizationMemberDialogComponent } from './_dialog/add-organization-member-dialog/add-organization-member-dialog.component';
import { AddPaymentDialogComponent } from './_dialog/add-payment-dialog/add-payment-dialog.component';
import { AddPaymentWizardDialogComponent } from './_dialog/add-payment-wizard-dialog/add-payment-wizard-dialog.component';
import { AddPaymentWizardStepComponent } from './_dialog/add-payment-wizard-dialog/add-payment-wizard-step/add-payment-wizard-step.component';
import { CloseAccountDialogComponent } from './_dialog/close-account-dialog/close-account-dialog.component';
import { ConfirmationDialogComponent } from './_dialog/confirmation-dialog/confirmation-dialog.component';
import { EditUserDialogComponent } from './_dialog/edit-user-dialog/edit-user-dialog.component';
import { OnHoldDialogComponent } from './_dialog/on-hold-dialog/on-hold-dialog.component';
import { PasswordResetDialogComponent } from './_dialog/password-reset-dialog/password-reset-dialog.component';
import { RequestAgentQuotaDialogComponent } from './_dialog/request-agent-quota-dialog/request-agent-quota-dialog.component';
import { RequireVerificationDialogComponent } from './_dialog/require-verification-dialog/require-verification-dialog.component';
import { SpinnerDialogComponent } from './_dialog/spinner-dialog/spinner-dialog.component';
import { HttpRequestInterceptor } from './_helpers/http-interceptor.interceptor';
import { simulatedBackendProvider } from './_helpers/simulated-backend';
import { AbsolutePipe } from './_pipes/absolute.pipe';
import { AsFormControlPipe } from './_pipes/as-form-control.pipe';
import { CalcTimePipe } from './_pipes/calc-time.pipe';
import { MathCeilingPipe } from './_pipes/math-ceiling.pipe';
import { SafeHTMLPipe } from './_pipes/safe-html.pipe';
import { TotalClusterCostsPipe } from './_pipes/total-cluster-costs..pipe';
import { AuthFooterComponent } from './account/auth-footer/auth-footer.component';
import { LoginComponent } from './account/login/login.component';
import { PasswordResetComponent } from './account/password-reset/password-reset.component';
import { RegistrationComponent } from './account/registration/registration.component';
import { VerifyComponent } from './account/verify/verify.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BillingComponent } from './billing/billing.component';
import { InvoiceDetailsComponent } from './billing/invoice-details/invoice-details.component';
import { AgentConfigurationComponent } from './cluster/agent-configuration/agent-configuration.component';
import { AgentDetailsComponent } from './cluster/agent-details/agent-details.component';
import { AgentsConfigurationComponent } from './cluster/agents-configuration/agents-configuration.component';
import { ClusterConfigurationComponent } from './cluster/cluster-configuration/cluster-configuration.component';
import { ClusterDetailsComponent } from './cluster/cluster-details/cluster-details.component';
import { ClusterListComponent } from './cluster/cluster-list.component';
import { CostsSwitcherComponent } from './cluster/costs-switcher/costs-switcher.component';
import { CostsComponent } from './cluster/costs/costs.component';
import { CreateClusterComponent } from './cluster/create-cluster/create-cluster.component';
import { PluginConfigurationComponent } from './cluster/plugin-configuration/plugin-configuration.component';
import { PluginDetailsComponent } from './cluster/plugin-details/plugin-details.component';
import { PluginsConfigurationComponent } from './cluster/plugins-configuration/plugins-configuration.component';
import { TotalCostsComponent } from './cluster/total-costs/total-costs.component';
import { AddressSettingsComponent } from './components/address-settings/address-settings.component';
import { BillingAddressStepComponent } from './components/billing-address-step/billing-address-step.component';
import { BillingDetailsStepComponent } from './components/billing-details-step/billing-details-step.component';
import { ButtonComponent } from './components/button/button.component';
import { CardBrandComponent } from './components/card-brand/card-brand.component';
import { CardDetailsComponent } from './components/card-details/card-details.component';
import { CardSettingsComponent } from './components/card-settings/card-settings.component';
import { ClusterStatusIconComponent } from './components/cluster-status-icon/cluster-status-icon.component';
import { ClusterStatusComponent } from './components/cluster-status/cluster-status.component';
import { CountrySelectComponent } from './components/country-select/country-select.component';
import { DialogBodyComponent } from './components/dialog-body/dialog-body.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { ClusterDismissibleNoticeComponent } from './components/dismissible-notice/cluster-dismissible-notice/cluster-dismissible-notice.component';
import { DismissibleNoticeComponent } from './components/dismissible-notice/dismissible-notice.component';
import { PaymentsDismissibleNoticeComponent } from './components/dismissible-notice/payments-dismissible-notice/payments-dismissible-notice.component';
import { StripeDismissibleNoticeComponent } from './components/dismissible-notice/stripe-dismissible-notice/stripe-dismissible-notice.component';
import { PasswordVisibilityComponent } from './components/password-visibility/password-visibility.component';
import { OrganizationAccountSettingsComponent } from './components/settings/organization-account-settings/organization-account-settings.component';
import { OrganizationAddressSettingsComponent } from './components/settings/organization-address-settings/organization-address-settings.component';
import { OrganizationMembersSettingsComponent } from './components/settings/organization-members-settings/organization-members-settings.component';
import { PaymentCardSettingsComponent } from './components/settings/payment-card-settings/payment-card-settings.component';
import { SettingsComponent } from './components/settings/settings.component';
import { UserChangePasswordSettingsComponent } from './components/settings/user-change-password-settings/user-change-password-settings.component';
import { UserPersonalInfoSettingsComponent } from './components/settings/user-personal-info-settings/user-personal-info-settings.component';
import { SidebarLayoutComponent } from './components/sidebar-layout/sidebar-layout.component';
import { SidebarProfileComponent } from './components/sidebar-layout/sidebar-profile/sidebar-profile.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StripeCardInputWrapperComponent } from './components/stripe-card-input-wrapper/stripe-card-input-wrapper.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { TabComponent } from './components/tabset/tab/tab.component';
import { TabsetComponent } from './components/tabset/tabset.component';
import { ToastComponent } from './components/toast/toast.component';
import { WakeClusterComponent } from './cluster/wake-cluster/wake-cluster.component';
import { MaintenanceComponent } from "./error-pages/maintenance/maintenance.component";
import { ExtractBalancePipe } from "./_pipes/extract-balance.pipe";
import { LabelComponent } from "./components/label/label.component";

// Register Swiper Element
register();

const MAT_DIALOG_CONFIG: MatDialogConfig = {
  // Enable backdrop
  hasBackdrop: true,
  // See `mat-dialog.scss`
  backdropClass: 'dialog-backdrop-blur',
  // Close dialogs on navigation
  closeOnNavigation: true,
};

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    NgxStripeModule.forRoot(),
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatSliderModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectCountryModule.forRoot('en'),
    MatSelectModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatSliderModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      extendedTimeOut: 0,
      tapToDismiss: false,
    }),
  ],
  declarations: [
    AppComponent,
    RegistrationComponent,
    ClusterDetailsComponent,
    BillingComponent,
    LoginComponent,
    VerifyComponent,
    AbsolutePipe,
    ExtractBalancePipe,
    ClusterListComponent,
    RequireVerificationDialogComponent,
    RequestAgentQuotaDialogComponent,
    PasswordResetComponent,
    ClusterStatusComponent,
    CalcTimePipe,
    CreateClusterComponent,
    InvoiceDetailsComponent,
    ToastComponent,
    SpinnerDialogComponent,
    PasswordResetDialogComponent,
    SvgIconComponent,
    SpinnerComponent,
    AuthFooterComponent,
    SidebarLayoutComponent,
    SidebarProfileComponent,
    ClusterStatusIconComponent,
    SettingsComponent,
    ButtonComponent,
    DialogHeaderComponent,
    DialogBodyComponent,
    AddPaymentDialogComponent,
    AddPaymentWizardDialogComponent,
    AddPaymentWizardStepComponent,
    DismissibleNoticeComponent,
    StripeDismissibleNoticeComponent,
    PaymentsDismissibleNoticeComponent,
    BillingDetailsStepComponent,
    BillingAddressStepComponent,
    StripeCardInputWrapperComponent,
    SafeHTMLPipe,
    TabComponent,
    TabsetComponent,
    SettingsComponent,
    UserPersonalInfoSettingsComponent,
    UserChangePasswordSettingsComponent,
    OrganizationAccountSettingsComponent,
    OrganizationAddressSettingsComponent,
    OrganizationMembersSettingsComponent,
    PaymentCardSettingsComponent,
    AddressSettingsComponent,
    CountrySelectComponent,
    AsFormControlPipe,
    SkeletonLoaderComponent,
    CardBrandComponent,
    CardDetailsComponent,
    CardSettingsComponent,
    PasswordVisibilityComponent,
    CloseAccountDialogComponent,
    AddOrganizationMemberDialogComponent,
    ConfirmationDialogComponent,
    EditUserDialogComponent,
    AgentConfigurationComponent,
    AgentsConfigurationComponent,
    ClusterDismissibleNoticeComponent,
    CostsComponent,
    PluginConfigurationComponent,
    PluginsConfigurationComponent,
    TotalCostsComponent,
    ClusterConfigurationComponent,
    TotalClusterCostsPipe,
    MathCeilingPipe,
    AgentDetailsComponent,
    PluginDetailsComponent,
    CostsSwitcherComponent,
    OnHoldDialogComponent,
    WakeClusterComponent,
    MaintenanceComponent,
    LabelComponent,
  ],
  providers: [
    simulatedBackendProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: MAT_DIALOG_CONFIG,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
