import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject, combineLatest, skip, startWith, takeUntil, tap } from 'rxjs';
import { RequestAgentQuotaDialogData } from 'src/app/_dialog/request-agent-quota-dialog/request-agent-quota-dialog-data.interface';
import { RequestAgentQuotaDialogComponent } from 'src/app/_dialog/request-agent-quota-dialog/request-agent-quota-dialog.component';
import { BillingPeriod, CustomerPrices } from 'src/app/_models/billing';
import { QuotaKey } from 'src/app/_models/quota';
import { AGENTS, Agent, AgentType } from 'src/app/_services/agents.token';

export type AgentsControlValue = {
  [key in AgentType]?: number;
};

const DEFAULT_AGENT_COUNT = 1;

@Component({
  selector: 'app-agents-configuration',
  templateUrl: './agents-configuration.component.html',
  styleUrls: ['./agents-configuration.component.scss'],
})
export class AgentsConfigurationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() billingPeriod!: BillingPeriod;
  @Input() customerPrices?: CustomerPrices;
  @Input() agentsControl = new UntypedFormControl({});
  @Input() maxQuota!: number;

  private terminator$ = new Subject<void>();

  readonly agentByType: Partial<Record<AgentType, Agent>> = {};
  readonly activeByAgentType: Partial<Record<AgentType, boolean>> = {};
  readonly sliderControlByAgentType: Partial<Record<AgentType, UntypedFormControl>> = {};
  readonly remainingQuotaByAgentType: Partial<Record<AgentType, number>> = {};
  readonly AgentType = AgentType;

  agentTypes: AgentType[] = [];
  allAgentsAdded: boolean = false;
  totalUsedQuota: number = 0;

  constructor(private matDialog: MatDialog, @Inject(AGENTS) public agents: Agent[]) {}

  ngOnInit(): void {
    this.agents.forEach((agent) => {
      this.agentByType[agent.type] = agent;
      this.sliderControlByAgentType[agent.type] = new UntypedFormControl(
        this.agentsControl.value[agent.type] ?? DEFAULT_AGENT_COUNT
      );
    });

    const sliderControlsValuesChanges = Object.entries(this.sliderControlByAgentType).map(
      ([agentType, sliderControl]) =>
        sliderControl.valueChanges.pipe(
          startWith(0),
          tap(() => this.updateRemainingQuotas(agentType as AgentType))
        )
    );

    combineLatest(sliderControlsValuesChanges)
      .pipe(skip(1), takeUntil(this.terminator$))
      .subscribe(() => {
        this.updateAgentsControl();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.maxQuotaChanges(changes);
    this.customerPricesChanges(changes);
  }

  ngOnDestroy(): void {
    this.terminator$.next();
    this.terminator$.complete();
  }

  onRequestQuota(): void {
    this.matDialog.open<RequestAgentQuotaDialogComponent, RequestAgentQuotaDialogData>(
      RequestAgentQuotaDialogComponent,
      {
        data: {
          quotaKey: QuotaKey.AGENTS_PER_CLUSTER,
        },
      }
    );
  }

  onAddAgent(agentType: AgentType): void {
    const totalUsedQuota = this.calculateTotalUsedQuota();

    if (totalUsedQuota >= this.maxQuota) {
      this.onRequestQuota();
      return;
    }

    this.activeByAgentType[agentType] = true;
    this.totalUsedQuota++;
    this.updateAgentTypes();
    this.updateAllAgentsAdded();
    this.updateAgentsControl();
    this.updateRemainingQuotas();
  }

  onRemoveAgent(agentType: AgentType): void {
    delete this.activeByAgentType[agentType];
    this.totalUsedQuota--;
    this.updateAgentTypes();
    this.updateAllAgentsAdded();
    this.resetSliderControl(agentType);
    this.updateAgentsControl();
    this.updateRemainingQuotas();
  }

  private maxQuotaChanges(changes: SimpleChanges): void {
    if (!changes['maxQuota']) {
      return;
    }

    if (!changes['maxQuota'].firstChange) {
      return;
    }

    this.agents.forEach((agent) => {
      this.remainingQuotaByAgentType[agent.type] = this.maxQuota;
    });
  }

  private customerPricesChanges(changes: SimpleChanges): void {
    if (!changes['customerPrices']) {
      return;
    }

    if (this.customerPrices) {
      this.agents.forEach((agent) => {
        if (this.agentsControl.value[agent.type]) {
          this.activeByAgentType[agent.type] = true;
          this.totalUsedQuota++;
          this.updateAgentTypes();
          this.updateAllAgentsAdded();
        }
      });
    }
  }

  private updateAgentTypes(): void {
    this.agentTypes = Object.keys(this.activeByAgentType) as AgentType[];
  }

  private updateAgentsControl(): void {
    const agentsControlValue: AgentsControlValue = {
      [AgentType.JAVA_UI_AUTOMATION]: 0,
      [AgentType.JAVA_API_AUTOMATION]: 0,
      [AgentType.DOTNET_UI_AUTOMATION]: 0,
      [AgentType.DOTNET_API_AUTOMATION]: 0,
    };

    this.agentTypes.forEach((agentType) => {
      agentsControlValue[agentType] = this.sliderControlByAgentType[agentType]!.value;
    });

    this.agentsControl.setValue(agentsControlValue);
  }

  private updateAllAgentsAdded(): void {
    this.allAgentsAdded = this.agents.every((agent) => this.activeByAgentType[agent.type]);
  }

  private resetSliderControl(agentType: AgentType): void {
    this.sliderControlByAgentType[agentType]!.setValue(DEFAULT_AGENT_COUNT);
  }

  private calculateTotalUsedQuota(): number {
    return Object.entries(this.sliderControlByAgentType)
      .filter(([agentType]) => this.agentTypes.includes(agentType as AgentType))
      .reduce((total, [, control]) => total + control.value, 0);
  }

  private updateRemainingQuotas(skipAgentType?: AgentType): void {
    this.totalUsedQuota = this.calculateTotalUsedQuota();

    this.agentTypes.forEach((agentType) => {
      if (agentType === skipAgentType) {
        return;
      }

      this.remainingQuotaByAgentType[agentType] =
        this.maxQuota - (this.totalUsedQuota - this.sliderControlByAgentType[agentType]!.value);
    });
  }
}
