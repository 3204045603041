<h5 class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">Agents</h5>

<p class="text-sm leading-sm text-gray-500 pb-5">
  Automation scripts are executed on Agents. Select the type and amount of agents for your cluster, agents can be changed later in the cluster details.
</p>

<div *ngIf="agentTypes.length" class="flex flex-col gap-5 pb-6">
  <ng-container *ngFor="let agentType of agentTypes">
    <ng-container *ngIf="agentByType[agentType] as agent">
      <ng-container *ngIf="sliderControlByAgentType[agentType] as sliderControl">
        <ng-container *ngIf="remainingQuotaByAgentType[agentType] as remainingQuota">
          <app-agent-configuration
            [agent]="agent"
            [billingPeriod]="billingPeriod"
            [customerPrices]="customerPrices"
            [sliderControl]="sliderControl"
            [remainingQuota]="remainingQuota"
            (agentRemove)="onRemoveAgent($event)"
          ></app-agent-configuration>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<div class="flex gap-3">
  <button
    type="button"
    [matMenuTriggerFor]="agentsMenu"
    [disabled]="allAgentsAdded"
    class="text-sm leading-sm font-medium text-gray-500 flex items-center gap-2 focus:outline-none focus:text-primary-400"
  >
    <app-svg-icon name="plus"></app-svg-icon>
    Add another agent
  </button>

  <div class="text-sm leading-sm text-gray-500">You are using {{ totalUsedQuota }} out of {{ maxQuota }} agents available in your current quota</div>

  <button type="button" (click)="onRequestQuota()" class="text-sm leading-sm link-0">Request higher quota</button>
</div>

<mat-menu #agentsMenu>
  <ng-container *ngFor="let agent of agents">
    <button
      *ngIf="!activeByAgentType[agent.type]"
      type="button"
      mat-menu-item
      matRipple
      (click)="onAddAgent(agent.type)"
    >
      <div class="flex items-start gap-3 text-left">
        <div class="w-12 h-8 bg-white border border-gray-100 rounded-md px-2 py-1">
          <img [src]="agent.imgSrc" [alt]="agent.imgAlt" class="w-full h-full" />
        </div>

        <div class="flex-1 text-sm leading-sm">
          <div class="font-medium text-gray-700">{{ agent.title }}</div>
          <div class="text-gray-500">{{ agent.description }}</div>
        </div>
      </div>
    </button>
  </ng-container>
</mat-menu>
